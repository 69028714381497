<ion-card
  class="ion-padding box-shadow border-radius ion-margin"
  [ngClass]="transaction.canceled ? 'canceled' : 'notCanceled'">
  <div class="content">
    <div class="top">
      <span>
        {{ transaction.establismentName }}
      </span>
      <span>
        {{ transaction.canceled ? 'Cancelada' : '' }}
      </span>
    </div>
    <br />
    <div class="top">
      <span> {{ transaction.canceled ? '-' : '' }} {{ transaction.purchaseValueEUR | currency: 'EUR' }}</span>
      <span> {{ transaction.date | date: 'HH:mm' | uppercase }}</span>
    </div>
  </div>
</ion-card>
