import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SharedFunctionsService } from 'src/app/@shared/services/shared-functions.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  loaderToShow: any;

  constructor(private loadingController: LoadingController, private sharedFunctionsService: SharedFunctionsService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('api.dreampay.com.br/api') > 0) {
      this.showLoader();
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && request.url.indexOf('api.dreampay.com.br/api') > 0) {
          // console.log('event--->>>', event);
          this.hideLoader();
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        // console.error(error);
        this.hideLoader();
        return throwError(error);
      })
    );
  }

  showLoader() {
    // console.log('show loader');
    this.sharedFunctionsService.incrementCounter();
    // console.log('show counter:', this.sharedFunctionsService.getCounter());
    if (this.sharedFunctionsService.getCounter() < 2) {
      this.loaderToShow = this.loadingController
        .create({
          message: 'Por favor aguarde...',
          duration: 500
        })
        .then(res => {
          res.present();
          // console.log('present loader');

          res.onDidDismiss().then(dis => {
            // console.log('Loading dismissed!');
          });
        });
    }
  }

  hideLoader() {
    // console.log('hide loader');
    this.sharedFunctionsService.decrementCounter();
    const counter = this.sharedFunctionsService.getCounter();

    // console.log('hide counter:', counter);
    if (counter == 0) {
      this.loadingController.dismiss().catch(err => {
        // console.log('no overlay');
      });
    }
  }
}
