<ion-app [class.dark-theme]="dark">
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content">
      <ion-content>
        <ion-list lines="none">
          <ion-img [src]="logo" class="logo-horizontal"></ion-img>
          <ion-menu-toggle autoHide="false" *ngFor="let p of appPages; let i = index">
            <ion-item (click)="pageClicked(p.title)" [routerLink]="p.url" routerLinkActive="selected" routerDirection="root" detail="false">
              <ion-icon slot="start" [name]="p.icon + '-outline'"></ion-icon>
              <ion-label>
                {{ p.title }}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <ion-list lines="none">
          <ion-list-header> Conta </ion-list-header>

          <ion-menu-toggle autoHide="false">
            <ion-item (click)="goToProfile()" routerLink="/perfil" routerLinkActive="selected" routerDirection="root" detail="false">
              <ion-icon slot="start" name="person"></ion-icon>
              <ion-label> Perfil </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-item>
            <ion-icon slot="start" name="moon-outline"></ion-icon>
            <ion-label> Dark Mode </ion-label>
            <ion-toggle [(ngModel)]="dark" (click)="themeChanged()"></ion-toggle>
          </ion-item>

          <ion-menu-toggle autoHide="false">
            <ion-item button (click)="logout()" detail="false">
              <ion-icon slot="start" name="log-out"></ion-icon>
              <ion-label> Sair </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <ion-list lines="none">
          <ion-list-header> Sobre a DreamPay </ion-list-header>
          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/tutorial" routerLinkActive="selected" routerDirection="root" detail="false">
              <span class="material-icons-outlined">tour</span>
              <ion-label>Tutorial</ion-label>
            </ion-item>

            <ion-item
              routerLink="/politica-privacidade"
              routerLinkActive="selected"
              routerDirection="root"
              detail="false">
              <span class="material-icons-outlined">policy</span>
              <ion-label>Política de Privacidade</ion-label>
            </ion-item>

            <ion-item routerLink="/termos-condicoes" routerLinkActive="selected" routerDirection="root" detail="false">
              <span class="material-icons-outlined">list_alt</span>
              <ion-label>Termos e Condições</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
