import { Component, Input } from '@angular/core';
import { PaymentLinks } from 'src/api/model';

@Component({
  selector: 'app-payment-link-card',
  templateUrl: './payment-link-card.component.html',
  styleUrls: ['./payment-link-card.component.scss']
})
export class PaymentLinkCardComponent {
  @Input() paymentLink: PaymentLinks;

  constructor() {}
}
