import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { NgxMixpanelService } from 'ngx-mixpanel';
import { take } from 'rxjs/operators';
import { CpfService, NameParams } from 'src/api/controllers/Cpf';
import { environment } from 'src/environments/environment';
import { stringSimilarity } from 'string-similarity-js';
import { getValidationConfigFromCardNo } from '../../helpers/card.helper';
import { Card } from '../../models/card-information';
import { User } from '../../models/user';
import { BinService } from '../../services/bin.service';
import { RefreshCardsService } from '../../services/refresh-cards.service';
import { SharedFunctionsService } from '../../services/shared-functions.service';
import { GenericValidator } from '../../validators/cpfValidator';
import { luhnValidator } from '../../validators/luhnValidator';

// import { SecureStorage, SecureStorageObject } from '@ionic-native/secure-storage';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() public card;
  @Input() public editCard = false;
  blocks;
  bloco1;
  bloco2;
  bloco3;
  bloco4;
  bloco5;
  bankName: string = 'Dreampay';
  ccForm: FormGroup;
  bin;
  cardImgPath = '';

  back = false;

  newCard: boolean = false;

  cardsArray: Card[] = [];
  existingCard;
  indexOfExistingCard;
  uid: string;
  user: User;

  constructor(
    private modalCtrl: ModalController,
    private binService: BinService,
    private toastController: ToastController,
    public alertController: AlertController,
    private sharedFunctionsService: SharedFunctionsService,
    private refreshCardService: RefreshCardsService,
    private cpfService: CpfService,
    private mixPanelService: NgxMixpanelService,
    private router: Router
  ) {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.uid = this.user.uid;
  }

  ngOnInit(): void {
    this.ccForm = new FormGroup({
      cardName: new FormControl(this.card.cardName, [
        Validators.required,
        Validators.maxLength(26),
        Validators.pattern('[A-Za-z ]+')
      ]),
      obfuscatedCardNumber: new FormControl(this.card.obfuscatedCardNumber, [
        Validators.required,
        Validators.minLength(11)
        // luhnValidator()
      ]),
      cardNumber: new FormControl(this.card.cardNumber, [
        Validators.required,
        Validators.minLength(11),
        luhnValidator()
      ]),
      cpf: new FormControl(this.card.cpf, [Validators.required, GenericValidator.isValidCpf()]),
      expirationDate: new FormControl(this.card.expirationDate, [
        Validators.required,
        Validators.maxLength(7),
        expDateValidators
      ]),
      cvc: new FormControl(this.card.cvc, [Validators.required, Validators.minLength(3), Validators.maxLength(4)]),
      brand: new FormControl(this.card.brand)
    });

    if (!this.card.cardName && !this.card.cardNumber) {
      this.newCard = true;
    }
    this.onChanges();

    this.blocks = this.ccForm.controls.obfuscatedCardNumber.value.split(' ');
    if (Array.isArray(this.blocks)) {
      this.bloco1 = this.blocks[0];
      this.bloco2 = this.blocks[1];
      this.bloco3 = this.blocks[2];
      this.bloco4 = this.blocks[3];
      this.bloco5 = this.blocks[4];
    } else {
      this.bloco1 = '';
      this.bloco2 = '';
      this.bloco3 = '';
      this.bloco4 = '';
      this.bloco5 = '';
    }
    this.blocks = this.card.obfuscatedCardNumber.split(' ');
    if (Array.isArray(this.blocks)) {
      this.bloco1 = this.blocks[0];
      this.bloco2 = this.blocks[1];
      this.bloco3 = this.blocks[2];
      this.bloco4 = this.blocks[3];
      this.bloco5 = this.blocks[4];
    } else {
      this.bloco1 = '';
      this.bloco2 = '';
      this.bloco3 = '';
      this.bloco4 = '';
      this.bloco5 = '';
    }
    var cardNumber = this.ccForm.controls.cardNumber.value.replaceAll(' ', '').trim();

    if (cardNumber) {
      this.binService
        .getCardInformation(cardNumber)
        .pipe(take(1))
        .subscribe(
          result => {
            this.bin = result;
            this.bankName = result.bank.name;
            this.cardImgPath = this.sharedFunctionsService.getCieloSchemaImageUrl(this.bin?.scheme || '');
          },
          error => {
            console.log('Erro a obter informações do cartão.', error);
          }
        );
    }

    SecureStoragePlugin.get({ key: this.uid }).then(value => {
      this.cardsArray = JSON.parse(value.value);
      this.existingCard = this.cardsArray?.find(p => {
        return p.cardNumber === this.ccForm.controls.cardNumber.value;
      });
      if (this.existingCard) {
        this.indexOfExistingCard = this.cardsArray.findIndex(
          obj => obj.cardNumber == this.ccForm.controls.cardNumber.value
        );
      }
    });
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'Remover cartão?',
      message: 'Tem a certeza que pretende remover o cartão? Esta ação é irreversível.',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: blah => {}
        },
        {
          text: 'Remover',
          id: 'confirm-button',
          handler: () => {
            this.removeCard();
            this.modalCtrl.dismiss({
              dismissed: true
            });
          }
        }
      ]
    });

    await alert.present();
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }

  onChanges() {
    this.ccForm.controls.obfuscatedCardNumber.valueChanges.subscribe(val => {
      this.blocks = this.ccForm.controls.obfuscatedCardNumber.value.split(' ');
      if (Array.isArray(this.blocks)) {
        this.bloco1 = this.blocks[0];
        this.bloco2 = this.blocks[1];
        this.bloco3 = this.blocks[2];
        this.bloco4 = this.blocks[3];
        this.bloco5 = this.blocks[4];
      } else {
        this.bloco1 = '';
        this.bloco2 = '';
        this.bloco3 = '';
        this.bloco4 = '';
        this.bloco5 = '';
      }
      var cardNumber = this.ccForm.controls.cardNumber.value.replaceAll(' ', '');
      this.ccForm.controls.cardNumber.setValue(val);

      if (cardNumber.length == 8) {
        this.binService
          .getCardInformation(cardNumber)
          .pipe(take(1))
          .subscribe(
            result => {
              this.bin = result;
              this.bankName = result.bank.name;

              this.cardImgPath = this.sharedFunctionsService.getCieloSchemaImageUrl(this.bin?.scheme || '');
            },
            error => {
              console.log('Erro a obter informações do cartão.', error);
            }
          );
      } else if (cardNumber.length < 8) {
        this.bankName = 'DreamPay';
      }
    });
  }

  cardNumber() {
    if (this.ccForm.controls.obfuscatedCardNumber.value) {
      this.presentToast('Não é possível editar o número de um cartão já adicionado.');
    }
  }

  submeter() {
    let cpfParams: NameParams = {
      cpf: this.ccForm.controls.cpf.value
    };

    if (this.ccForm.valid) {
      this.cpfService
        .name(cpfParams)
        .pipe(take(1))
        .subscribe(cpfName => {
          let similarity = stringSimilarity(cpfName.customerName, this.ccForm.controls.cardName.value);
          console.log('similatiry', similarity);
          if (similarity < 0.5) {
            this.presentToast('O CPF indicado não parece ser do titular do cartão.');
          } else {
            const cardNumber = this.ccForm.controls.cardNumber.value;
            this.ccForm.controls.brand.setValue(this.sharedFunctionsService.getCieloSchemaName(this.bin?.scheme || ''));
            this.ccForm.controls.obfuscatedCardNumber.setValue(
              this.obfuscateCreditCardNumber(this.ccForm.controls.obfuscatedCardNumber.value)
            );
            this.ccForm.controls.cardNumber.setValue(cardNumber.replaceAll(' ', ''));

            if (!this.cardsArray) {
              this.cardsArray = [];
              this.cardsArray.push(this.ccForm.value);
              const cards = JSON.stringify(this.cardsArray);
              SecureStoragePlugin.set({ key: this.uid, value: cards });
              this.presentToast('Cartão adicionado');
              this.refreshCardService.setCardNumber(this.ccForm.controls.cardNumber.value);
            } else {
              if (this.existingCard) {
                this.cardsArray[this.indexOfExistingCard] = this.ccForm.value;
                const cards = JSON.stringify(this.cardsArray);
                SecureStoragePlugin.set({ key: this.uid, value: cards });
                this.presentToast('Cartão editado');
                if (environment.env === 'prod') {
                  this.mixPanelService.eventRegister('Cartão editado.', this.router.url, { user: this.user.email });
                }
                this.refreshCardService.setCardNumber(this.ccForm.controls.cardNumber.value);
              } else {
                this.cardsArray.push(this.ccForm.value);
                const cards = JSON.stringify(this.cardsArray);
                SecureStoragePlugin.set({ key: this.uid, value: cards });
                this.presentToast('Cartão adicionado');
                if (environment.env === 'prod') {
                  this.mixPanelService.eventRegister('Cartão adicionado.', this.router.url,  { user: this.user.email });
                }
                this.refreshCardService.setCardNumber(this.ccForm.controls.cardNumber.value);
              }
            }
            this.modalCtrl.dismiss({
              dismissed: true
            });
          }
        });
    } else {
      console.log('error', this.ccForm.controls.errors);
      this.presentToast('Não foi possível adicionar o cartão. Por favor preencha todos os campos com dados válidos.');
      if (environment.env === 'prod') {
        this.newCard
          ? this.mixPanelService.eventRegister('Tentativa de adicionar cartão falhada.', this.router.url,  { user: this.user.email })
          : this.mixPanelService.eventRegister('Tentativa de editar cartão falhada.', this.router.url,  { user: this.user.email });
      }
    }
  }

  removeCard() {
    this.cardsArray = this.cardsArray.filter(x => {
      return x !== this.existingCard;
    });
    const cards = JSON.stringify(this.cardsArray);
    SecureStoragePlugin.set({ key: this.uid, value: cards });
    this.refreshCardService.setCardNumber(this.ccForm.controls.cardNumber.value);
    if (environment.env === 'prod') {
      this.mixPanelService.eventRegister('Cartão eliminado.', this.router.url,  { user: this.user.email });
    }
  }

  cardMaskFunction(rawValue: string): Array<RegExp> {
    const card = getValidationConfigFromCardNo(rawValue);
    if (card) {
      return card.mask;
    }
    return [/\d/];
  }

  cancel() {
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  obfuscateCreditCardNumber(cardNumber: string) {
    return (
      cardNumber.substring(0, 7) + '*'.repeat(cardNumber.length - 10) + cardNumber.substring(cardNumber.length - 5)
    );
  }
}

function expDateValidators(c: FormControl) {
  const monthAndYear = /^(0[1-9]|1[0-2])\/?([2-9][0-9])$/;

  return monthAndYear.test(c.value)
    ? null
    : {
        validateInput: {
          valid: false
        }
      };
}
