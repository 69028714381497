import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { NgxMixpanelService } from 'ngx-mixpanel';
import { environment } from 'src/environments/environment';
import { User } from '../../models/user';

@Component({
  selector: 'app-transaction-details-modal',
  templateUrl: './transaction-details-modal.component.html',
  styleUrls: ['./transaction-details-modal.component.scss']
})
export class TransactionDetailsModalComponent {
  transaction;
  user: User;

  constructor(private modalCtrl: ModalController, private mixPanelService: NgxMixpanelService, private router: Router) {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (environment.env === 'prod') {
      this.mixPanelService.eventRegister('Detalhes de transação consultados.', this.router.url, {
        transaction: this.transaction,
        user: this.user.email
      });
    }
  }

  dismiss() {
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }
}
