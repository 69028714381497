import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxMixpanelService } from 'ngx-mixpanel';
import { Observable } from 'rxjs';
import { EstablishmentsAddressesService } from 'src/api/controllers/EstablishmentsAddresses';
import { Establishments_Addresses } from 'src/api/model';
import { environment } from 'src/environments/environment';
import { User } from '../../models/user';

@Component({
  selector: 'app-establishment-details',
  templateUrl: './establishment-details.component.html',
  styleUrls: ['./establishment-details.component.scss']
})
export class EstablishmentDetailsComponent implements OnInit {
  establishment;
  $addresses: Observable<Establishments_Addresses[]>;
  user: User;

  constructor(
    private establishmentsAddressesService: EstablishmentsAddressesService,
    private mixPanelService: NgxMixpanelService,
    private router: Router
  ) {
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  ngOnInit(): void {
    if (environment.env === 'prod') {
      this.mixPanelService.eventRegister('Pareceiro visitado.', this.router.url, {
        establishment: this.establishment,
        user: this.user.email
      });
    }
    this.$addresses = this.establishmentsAddressesService.getEstablishmentsAddresses();
  }

  getEstablishmentUrl(url) {
    let pattern = /^((http|https|ftp):\/\/)/;

    if (!pattern.test(url)) {
      return 'https://' + url;
    } else {
      return url;
    }
  }

  urlClicked(establishmentUrl) {
    if (environment.env === 'prod') {
      this.mixPanelService.eventRegister('Website de estabelecimento visitado.', this.router.url, {
        url: establishmentUrl,
        user: this.user.email
      });
    }
  }
}
