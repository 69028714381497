import {HttpClient, HttpEvent, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';

import * as __model from '../model';

export interface PostEstablishmentsAddressesParams {
  body?: __model.Establishments_Addresses;
}

export interface GetEstablishmentsAddressesByIdParams {
  id: number;
}

export interface PutEstablishmentsAddressesByIdParams {
  id: number;
  body?: __model.Establishments_Addresses;
}

export interface DeleteEstablishmentsAddressesByIdParams {
  id: number;
}

@Injectable()
export class EstablishmentsAddressesService {
  headers = new HttpHeaders({ "Content-Type": "application/json" });

  constructor(private http: HttpClient) {}

  getEstablishmentsAddresses(): Observable<__model.Establishments_Addresses[]> {
    return this.http.get<__model.Establishments_Addresses[]>(environment.apiUrl + `/api/Establishments_Addresses`, {headers: this.headers});
  }

  postEstablishmentsAddresses(params: PostEstablishmentsAddressesParams): Observable<__model.Establishments_Addresses> {
    const bodyParams = params.body;

    return this.http.post<__model.Establishments_Addresses>(environment.apiUrl + `/api/Establishments_Addresses`, bodyParams || {}, {headers: this.headers});
  }

  getEstablishmentsAddressesById(params: GetEstablishmentsAddressesByIdParams): Observable<__model.Establishments_Addresses> {
    const pathParams = {
      id: params.id,
    };
    return this.http.get<__model.Establishments_Addresses>(environment.apiUrl + `/api/Establishments_Addresses/${pathParams.id}`, {headers: this.headers});
  }

  putEstablishmentsAddressesById(params: PutEstablishmentsAddressesByIdParams): Observable<void> {
    const pathParams = {
      id: params.id,
    };
    const bodyParams = params.body;

    return this.http.put<void>(environment.apiUrl + `/api/Establishments_Addresses/${pathParams.id}`, bodyParams || {}, {headers: this.headers});
  }

  deleteEstablishmentsAddressesById(params: DeleteEstablishmentsAddressesByIdParams): Observable<__model.Establishments_Addresses> {
    const pathParams = {
      id: params.id,
    };
    return this.http.delete<__model.Establishments_Addresses>(environment.apiUrl + `/api/Establishments_Addresses/${pathParams.id}`, {headers: this.headers});
  }
}
