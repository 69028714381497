import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { Capacitor } from '@capacitor/core';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MenuController, Platform, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { NgxMixpanelService } from 'ngx-mixpanel';
import { environment } from 'src/environments/environment';
import { User } from './@shared/models/user';
import { AuthenticationService } from './@shared/services/authentication.service';
import { FirestoreService } from './@shared/services/firestore.service';
import { LogService } from './@shared/services/log.service';
import { UserData } from './@shared/services/user-data';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  logo: string = '../../../assets/img/logo-horizontal-light.png';

  appPages = [
    {
      title: 'Início',
      url: '/app/tabs/inicio',
      icon: 'home'
    },
    {
      title: 'Transações',
      url: '/app/tabs/transactions',
      icon: 'list'
    },
    {
      title: 'QR Code',
      url: '/app/tabs/qr-code',
      icon: 'scan'
    },
    {
      title: 'Cartões',
      url: '/app/tabs/cards',
      icon: 'card'
    },
    {
      title: 'Parceiros',
      url: '/app/tabs/lojas',
      icon: 'storefront'
    }
  ];
  loggedIn = false;
  dark = false;
  user: User;

  constructor(
    // public auth: AngularFireAuth,
    private authenticationService: AuthenticationService,
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private userData: UserData,
    private swUpdate: SwUpdate,
    private ga: GoogleAnalytics,
    private logger: LogService,
    private toastCtrl: ToastController,
    private firestoreService: FirestoreService,
    private mixPanelService: NgxMixpanelService
  ) {
    this.initializeApp();
    mixPanelService.config('c9968d4ffc5db5121d26d9d010022819');
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  async ngOnInit() {
    this.checkLoginStatus();
    this.listenForLoginEvents();

    this.swUpdate.available.subscribe(async res => {
      const toast = await this.toastCtrl.create({
        message: 'Update available!',
        position: 'bottom',
        buttons: [
          {
            role: 'cancel',
            text: 'Reload'
          }
        ]
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });
  }

  themeChanged() {
    if (!this.dark) {
      localStorage.setItem('theme', 'dark');
      if (environment.env === 'prod') {
        this.mixPanelService.eventRegister('Tema alterado para escuro.', this.router.url, { user: this.user.email });
      }
      this.logo = '../../../assets/img/logo-horizontal-dark.png';
    } else {
      localStorage.setItem('theme', 'light');
      if (environment.env === 'prod') {
        this.mixPanelService.eventRegister('Tema alterado para claro.', this.router.url, { user: this.user.email });
      }
      this.logo = '../../../assets/img/logo-horizontal-light.png';
    }
  }

  goToProfile() {
    if (environment.env === 'prod') {
      this.mixPanelService.eventRegister('Perfil visitado', this.router.url, { user: this.user.email });
    }
  }

  pageClicked(pageTitle) {
    if (pageTitle === 'Transações') {
      if (environment.env === 'prod') {
        this.mixPanelService.eventRegister('Página de transações consultada.', this.router.url, {
          user: this.user.email
        });
      }
    }
  }

  initializeApp() {
    // const app = getApp();
    // if (Capacitor.isNativePlatform) {
    //   initializeAuth(app, {
    //     persistence: indexedDBLocalPersistence
    //   });
    // }
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      var platform = Capacitor.getPlatform().toLowerCase();
      if (platform == 'ios' || platform == 'android') {
        this.ga
          .startTrackerWithId(environment.googleAnalyticsTrackerId)
          .then(() => {
            this.ga
              .trackView('Outbox')
              .then(() => {})
              .catch(error => console.log(error));
          })
          .catch(error => this.logger.error('Google Analytics Error: ' + error));
      }
    });
  }

  checkLoginStatus() {
    return this.userData.isLoggedIn().then(loggedIn => {
      return this.updateLoggedInStatus(loggedIn);
    });
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
      if (loggedIn) {
        let user = JSON.parse(localStorage.getItem('user'));
        this.firestoreService.getUser(user.uid).then(result => {
          if (Capacitor.getPlatform().toLowerCase() !== 'ios' && !result.phoneValidated) {
            this.router.navigateByUrl('/verificar-numero');
          }
        });
      }
    }, 300);
  }

  listenForLoginEvents() {
    window.addEventListener('user:login', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:signup', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:logout', () => {
      this.updateLoggedInStatus(false);
    });
  }

  logout() {
    this.authenticationService.SignOut();
    this.userData.logout().then(() => {
      return this.router.navigateByUrl('/login');
    });
    if (environment.env === 'prod') {
      this.mixPanelService.eventRegister('Utilizador fez logout.', this.router.url, { user: this.user.email });
    }
  }

  openTutorial() {
    this.menu.enable(false);
    this.storage.set('ion_did_tutorial', false);
    this.router.navigateByUrl('/tutorial');
    if (environment.env === 'prod') {
      this.mixPanelService.eventRegister('Tutorial visitado após login.', this.router.url, { user: this.user.email });
    }
  }

  openPoliticaPrivacidade() {
    this.router.navigateByUrl('/politica-privacidade');
    if (environment.env === 'prod') {
      this.mixPanelService.eventRegister('Política de privacidade visitada.', this.router.url, {
        user: this.user.email
      });
    }
  }

  openTermosCondicoes() {
    this.router.navigateByUrl('/termos-condicoes');
    if (environment.env === 'prod') {
      this.mixPanelService.eventRegister('Termos e condições visitados.', this.router.url, { user: this.user.email });
    }
  }

  ionViewDidEnter(): void {
    this.menu.enable(false);
  }

  ionViewDidLeave(): void {
    this.menu.enable(true);
  }
}
