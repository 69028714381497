<ion-header>
  <ion-toolbar>
    <ion-title>Transação<span *ngIf="transaction.canceled"> cancelada</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="content">
    <ion-card class="transaction">
      <ion-card-subtitle> Comprei </ion-card-subtitle>
      <ion-card-header>
        <ion-card-title> {{ transaction.purchaseValueEUR | currency: 'EUR' }} </ion-card-title>
      </ion-card-header>
      <ion-card-subtitle>em {{ transaction.establismentName }}</ion-card-subtitle>
    </ion-card>
  </div>
  <ion-card>
    <ion-item>
      <ion-label>Data</ion-label>
      <ion-label slot="end">{{ transaction.date | date: 'dd.MM.yyyy' }}</ion-label>
    </ion-item>
    <ion-item>
      <ion-label>Parcelas</ion-label>
      <ion-label slot="end">{{ transaction.installments }}</ion-label>
    </ion-item>
    <ion-item class="last">
      <ion-label>Cartão</ion-label>
      <ion-label slot="end">{{ transaction.cardNumber }}</ion-label>
    </ion-item>
  </ion-card>
</ion-content>
