import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { IonicModule } from '@ionic/angular';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxMaskModule } from 'ngx-mask';
import { CpfService } from 'src/api/controllers/Cpf';
import { LogsService } from '../../api/controllers/Logs';
import { CardEditComponent } from './components/card-edit/card-edit.component';
import { CardComponent } from './components/card/card.component';
import { EstablishmentDetailsComponent } from './components/establishment-details/establishment-details.component';
import { GoogleLoginComponent } from './components/google-login/google-login.component';
import { PaymentLinkCardComponent } from './components/payment-link-card/payment-link-card.component';
import { PaymentLinksDetailsComponent } from './components/payment-links-details/payment-links-details.component';
import { TransactionCardComponent } from './components/transaction-card/transaction-card.component';
import { TransactionDetailsModalComponent } from './components/transaction-details-modal/transaction-details-modal.component';
import { GroupByDatePipe } from './pipes/group-by-date.pipe';
import { SearchEstablishmentsPipe } from './pipes/search-establishments.pipe';
import { BinService } from './services/bin.service';
import { NgxMixpanelService } from 'ngx-mixpanel';

@NgModule({
  declarations: [
    GoogleLoginComponent,
    GroupByDatePipe,
    TransactionCardComponent,
    PaymentLinkCardComponent,
    TransactionDetailsModalComponent,
    EstablishmentDetailsComponent,
    PaymentLinksDetailsComponent,
    CardComponent,
    CardEditComponent,
    SearchEstablishmentsPipe
  ],
  exports: [
    GoogleLoginComponent,
    GroupByDatePipe,
    SearchEstablishmentsPipe,
    TransactionCardComponent,
    PaymentLinkCardComponent,
    TransactionDetailsModalComponent,
    EstablishmentDetailsComponent,
    CardComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    AngularFireAuthModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    TextMaskModule
  ],
  providers: [GooglePlus, LogsService, BinService, CpfService, NgxMixpanelService]
})
export class SharedModule {}
