<ion-header>
  <ion-toolbar>
    <ion-title
      >Pagamento pendente
      <span *ngIf="paymentLink.canceled"> cancelado</span>
      <span *ngIf="paymentLink.expired"> expirado</span>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="content">
    <ion-card class="transaction">
      <ion-card-subtitle> A pagar </ion-card-subtitle>
      <ion-card-header>
        <ion-card-title> {{ paymentLink.valueEUR | currency: 'EUR' }} </ion-card-title>
      </ion-card-header>
      <ion-card-subtitle *ngIf="paymentLink.establishmentName">a {{ paymentLink.establishmentName }}</ion-card-subtitle>
    </ion-card>
  </div>
  <ion-card>
    <ion-item>
      <ion-label>Data</ion-label>
      <ion-label slot="end">{{ paymentLink.date | date: 'dd.MM.yyyy' }}</ion-label>
    </ion-item>

    <ion-item *ngIf="paymentLink?.observations !== '-'">
      <ion-label>Obs.</ion-label>
      <ion-label slot="end">{{ paymentLink.observations }}</ion-label>
    </ion-item>
    <ion-item>
      <ion-button *ngIf="!cards || cards?.length === 0" class="payBtn" (click)="addCard()">Adicionar cartão</ion-button>
      <ion-button *ngIf="cards?.length !== 0 && cards" class="payBtn" (click)="pay(paymentLink)">Pagar</ion-button>
    </ion-item>
  </ion-card>
</ion-content>
