import { Injectable } from '@angular/core';
import { doc, Firestore, getDoc, setDoc } from '@angular/fire/firestore';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {
  phoneValidated;

  constructor(private firestore: Firestore, private router: Router) {}

  async addUserIfNotExists(uid: string) {
    const userDocRef = doc(this.firestore, `users/${uid}`);

    await getDoc(userDocRef)
      .then(doc => {
        console.log('doc firestore', doc);
        if (!doc.data()) {
          setDoc(userDocRef, {
            phoneValidated: false,
            uid: uid,
            phoneNumber: null,
            recaptchaToken: null,
            sessionInfoToken: null
          });
        }
      })
      .catch(error => {
        console.log('Error getting profile from Firestore', error);
      });
  }

  async getUser(uid: string) {
    const userDocRef = doc(this.firestore, `users/${uid}`);
    return await getDoc(userDocRef).then(doc => {
      console.log('doc firestore getUser', doc.data());
      this.phoneValidated = doc.data().phoneValidated ? doc.data().phoneValidated : false;
      return doc.data();
    });
  }

  async updateUser(uid: string, userData: any) {
    const userDocRef = doc(this.firestore, `users/${uid}`);

    await getDoc(userDocRef)
      .then(doc => {
        if (doc.data()) {
          console.log('doc.data() updateUser', doc.data());
          this.phoneValidated = userData.phoneValidated;
          console.log('userDATA', userData);
          if (userData.phoneValidated) {
            this.router.navigateByUrl('/app/tabs/inicio');
          }
          setDoc(userDocRef, userData, { merge: true });
          console.log('doc data update AFTER', doc.data());
        }
      })
      .catch(error => {
        console.log('Error getting profile from Firestore', error);
      });
  }

  get isPhoneValidated(): boolean {
    return this.phoneValidated;
  }
}
