import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './@core/guards/auth.guard';
import { VerifyNumberGuard } from './@core/guards/verify-number.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/tutorial',
    pathMatch: 'full'
  },
  {
    path: 'perfil',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard, VerifyNumberGuard]
  },
  {
    path: 'verificar-numero',
    loadChildren: () => import('./pages/verify-number/verify-number.module').then(m => m.VerifyNumberModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'politica-privacidade',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
  },
  {
    path: 'termos-condicoes',
    loadChildren: () => import('./pages/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule)
  },

  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignUpModule)
  },
  {
    path: 'recover-password',
    loadChildren: () => import('./pages/recover-password/recover-password.module').then(m => m.RecoverPasswordModule)
  },
  {
    path: 'app',
    loadChildren: () => import('./pages/tabs-page/tabs-page.module').then(m => m.TabsModule),
    canActivate: [AuthGuard, VerifyNumberGuard]
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then(m => m.TutorialModule)
    // canLoad: [CheckTutorial]
  },
  {
    path: 'links',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/payment-links/payment-links.module').then(m => m.PaymentLinksModule)
      }
    ]
  },
  {
    path: 'pay/:id',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/pay/pay.module').then(m => m.PayModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
