import { Injectable } from '@angular/core';
import {
  Auth,
  authState,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  userData: User;

  constructor(public auth: Auth, private router: Router, private toastController: ToastController) {
    authState(this.auth).subscribe(user => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    });
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000
    });
    toast.present();
  }

  SignIn(email: string, password: string) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  RegisterUser(email: string, password: string) {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  PasswordRecover(passwordResetEmail: string) {
    return sendPasswordResetEmail(this.auth, passwordResetEmail);
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null && user.emailVerified !== false ? true : false;
  }

  get isEmailVerified(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user.emailVerified !== false ? true : false;
  }

  GoogleAuth() {
    return this.AuthLogin(new GoogleAuthProvider());
  }

  AppleAuth() {
    return this.AuthLogin(new OAuthProvider('apple.com'));
  }

  AuthLogin(provider: any) {
    signInWithPopup(this.auth, provider)
      .then(result => {
        this.router.navigate(['/home']);
        this.SetUserData(result.user);
      })
      .catch(error => {
        this.presentToast('Ocorreu um erro ao fazer login.');
      });
  }

  SetUserData(user: User) {
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified
    };
    localStorage.setItem('user', JSON.stringify(userData));
  }

  SignOut() {
    return this.auth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['/login']);
    });
  }

  async sendEmailVerification() {
    sendEmailVerification(await await this.auth.currentUser);
  }

  async sendPasswordResetEmail(passwordResetEmail: string) {
    return await sendPasswordResetEmail(this.auth, passwordResetEmail);
  }
}
