import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AuthenticationService } from './authentication.service';
import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root'
})
export class UserData {
  favorites: string[] = [];
  HAS_LOGGED_IN = 'hasLoggedIn';
  HAS_SEEN_TUTORIAL = 'hasSeenTutorial';

  constructor(
    public storage: Storage,
    private auth: AuthenticationService,
    private toastController: ToastController,
    private firestoreService: FirestoreService
  ) {}

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000
    });
    toast.present();
  }

  hasFavorite(sessionName: string): boolean {
    return this.favorites.indexOf(sessionName) > -1;
  }

  addFavorite(sessionName: string): void {
    this.favorites.push(sessionName);
  }

  removeFavorite(sessionName: string): void {
    console.log('signup');
    const index = this.favorites.indexOf(sessionName);
    if (index > -1) {
      this.favorites.splice(index, 1);
    }
  }

  login(email: string, password: string): Promise<any> {
    console.log('login');
    return this.auth
      .SignIn(email, password)
      .then(user => {
        console.log('login.1', user);
        if (!user.user.emailVerified) {
          console.log('email não verificado');
          this.presentToast('Email não está verificado.');
        }
        this.storage.set(this.HAS_LOGGED_IN, true).then(() => {
          console.log('login.2');
          this.setEmail(email);
          this.firestoreService.addUserIfNotExists(user.user.uid);
          return window.dispatchEvent(new CustomEvent('user:login'));
        });
      })
      .catch(error => {
        this.presentToast('Email/password errado.');
        console.log('erro: ', error);
      });
  }

  signup(email: string, password: string): Promise<any> {
    return this.auth
      .RegisterUser(email, password)
      .then(user => {
        this.storage.set(this.HAS_LOGGED_IN, true).then(() => {
          this.setEmail(email);
          this.auth.sendEmailVerification();
          this.firestoreService.addUserIfNotExists(user.user.uid);
          return window.dispatchEvent(new CustomEvent('user:signup'));
        });
      })
      .catch(error => {
        this.presentToast('O email que inseriu já se encontra registado.');
        console.log('erro: ', error);
      });
  }

  logout(): Promise<any> {
    return this.storage
      .remove(this.HAS_LOGGED_IN)
      .then(() => {
        return this.storage.remove('email');
      })
      .then(() => {
        window.dispatchEvent(new CustomEvent('user:logout'));
      });
  }

  setEmail(email: string): Promise<any> {
    console.log('setEmail', email);
    return this.storage.set('email', email);
  }

  getEmail(): Promise<string> {
    return this.storage.get('email').then(value => {
      return value;
    });
  }

  isLoggedIn(): Promise<boolean> {
    return this.storage.get(this.HAS_LOGGED_IN).then(value => {
      return value === true;
    });
  }

  checkHasSeenTutorial(): Promise<string> {
    return this.storage.get(this.HAS_SEEN_TUTORIAL).then(value => {
      return value;
    });
  }
}
