import { Component,Input } from '@angular/core';

import { Auth } from '@angular/fire/auth';

import { Router } from '@angular/router';

import { GooglePlus } from '@ionic-native/google-plus/ngx';

import { NavController,Platform } from '@ionic/angular';

import { User } from 'firebase/auth';

import { Observable } from 'rxjs';

import { UserData } from '../../services/user-data';


@Component({
  selector: 'app-google-login',
  templateUrl: './google-login.component.html',
  styleUrls: ['./google-login.component.scss']
})
export class GoogleLoginComponent {
  @Input() homePath: string;
  user: Observable<User>;

  constructor(
    public auth: Auth,
    private gplus: GooglePlus,
    private platform: Platform,
    private router: Router,
    public userData: UserData,
    public navCtrl: NavController
  ) {
    // this.user = this.auth.authState;
    // this.user.pipe(take(1)).subscribe(res => {
    //   if (res != null) {
    //     this.navigateAfterSomeTime(this.homePath, 1000, this.router);
    //   }
    // });
  }

  // navigateAfterSomeTime(homePath, time, router) {
  //   setTimeout(function () {
  //     router.navigateByUrl(homePath);
  //   }, time);
  // }

  googleLogin() {
    //   if (this.platform.is('cordova')) {
    //     this.nativeGoogleLogin();
    //   } else {
    //     this.login();
    //   }
  }

  // async nativeGoogleLogin(): Promise<void> {
  //   const params = {
  //     webClientId: environment.webClientId, //  webclientID 'string'
  //     offline: true
  //   };
  //   try {
  //     const gplusUser = await this.gplus.login(params);
  //     await this.auth.signInWithCredential(GoogleAuthProvider.credential(gplusUser.idToken)).then(result => {
  //       console.log(result);
  //       this.userData.setEmail(result.user.email);
  //       this.router.navigateByUrl(this.homePath);
  //     });
  //   } catch (err) {
  //     // TODO - display error message
  //   }
  // }

  // login() {
  //   this.auth.signInWithPopup(new GoogleAuthProvider()).then(result => {
  //     console.log(result);
  //     this.userData.setEmail(result.user.email);
  //     this.router.navigateByUrl(this.homePath);
  //   });
  // }
}
