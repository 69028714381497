import {HttpClient, HttpEvent, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';

import * as __model from '../model';

export interface NameParams {
  cpf: string;
}

@Injectable()
export class CpfService {
  headers = new HttpHeaders({ "Content-Type": "application/json" });

  constructor(private http: HttpClient) {}

  name(params: NameParams): Observable<__model.CpfInfo> {
    const pathParams = {
      cpf: params.cpf,
    };
    return this.http.get<__model.CpfInfo>(environment.apiUrl + `/api/Cpf/name/${pathParams.cpf}`, {headers: this.headers});
  }
}
