export const environment = {
  appId: 'dreampay-app',
  env: 'dev',
  production: false,
  apiKey: '8edVQyVI35xjmKB4DVIhdiEAW0LTLRLY',
  webClientId: '',
  sentryDsn: 'https://7c8ba9b3107c4a86ab875fbd6a44da6e@o1114358.ingest.sentry.io/6191340',
  googleAnalyticsTrackerId: 'G-77GEEEEPL5',
  apiUrl: 'https://dev-private-api.dreampay.com.br',
  API_BASE_PATH: 'https://dev-private-api.dreampay.com.br',
  apiURLCielo: 'https://apisandbox.cieloecommerce.cielo.com.br',
  apiQueryURLCielo: 'https://apiquerysandbox.cieloecommerce.cielo.com.br',
  merchantIdCielo: '2deb432e-0d51-4dd5-a1dd-c9373b29bdfe',
  merchantKeyCielo: 'MMDNPUAHZKMCEISVDOSMMJKHTLXIYQTICFESLRDI',
  firebase: {
    projectId: 'dreampay-app',
    appId: '1:1009251325182:web:87e6bb10cff0fe146a41af',
    storageBucket: 'dreampay-app.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyD9GJJAliJ5xYGHukTx8524zWu627cS0bo',
    authDomain: 'dreampay-app.firebaseapp.com',
    messagingSenderId: '1009251325182',
  }
};
