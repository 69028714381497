<ion-header>
  <ion-toolbar>
    <ion-title>{{ establishment.establismentName }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card>
    <div class="website" *ngIf="establishment.websiteUrl">
      <span>Website </span>
      <span>
        <a (click)="urlClicked(establishment.websiteUrl)" [href]="getEstablishmentUrl(establishment.websiteUrl)">{{ establishment.websiteUrl }}</a>
      </span>
    </div>
  </ion-card>
  <ion-card>
    <div class="locations">
      <span class="title-locations">Localizações</span>
      <div class="address">
        <span>{{ establishment.address }}</span>
        <span> {{ establishment.zipCode }}, {{ establishment.city }}</span>
        <span> {{ establishment.phoneNumber }}</span>
      </div>
      <div class="address" *ngFor="let address of $addresses | async">
        <span>{{ address.address }}</span>
        <span> {{ address.zipCode }}, {{ address.city }}</span>
        <span> {{ address.phoneNumber }}</span>
      </div>
    </div>
  </ion-card>
</ion-content>
