import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { NgxMixpanelService } from 'ngx-mixpanel';
import { environment } from 'src/environments/environment';
import { User } from '../../models/user';
import { CardEditComponent } from '../card-edit/card-edit.component';

@Component({
  selector: 'app-payment-links-details',
  templateUrl: './payment-links-details.component.html',
  styleUrls: ['./payment-links-details.component.scss']
})
export class PaymentLinksDetailsComponent {
  paymentLink;
  uid;
  cards;
  user: User;

  constructor(private modalCtrl: ModalController, private router: Router, private mixPanelService: NgxMixpanelService) {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.uid = this.user.uid;
    SecureStoragePlugin.get({ key: this.uid }).then(data => {
      this.cards = JSON.parse(data.value);
    });
  }

  dismiss() {
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  addCard() {
    this.addNewCard();
  }

  pay(paymentLink) {
    if (environment.env === 'prod') {
      this.mixPanelService.eventRegister('Pagamento através de modal na home.', this.router.url, {
        paymentLink: paymentLink,
        user: this.user.email
      });
    }
    this.router.navigateByUrl('/pay/' + paymentLink.id, { replaceUrl: true });
    this.dismiss();
  }

  async addNewCard() {
    if (environment.env === 'prod') {
      this.mixPanelService.eventRegister(
        'Redirecionado para adicionar cartão a partir de modal de pagamento na home.',
        this.router.url,
        { user: this.user.email }
      );
    }
    const modal = await this.modalCtrl.create({
      component: CardEditComponent,
      componentProps: {
        card: {
          cpf: '',
          obfuscatedCardNumber: '',
          cardNumber: '',
          cardName: '',
          cvc: '',
          expirationDate: '',
          brand: ''
        }
      }
    });
    modal.onDidDismiss().then(() => {
      SecureStoragePlugin.get({ key: this.uid }).then(data => {
        this.cards = JSON.parse(data.value);
      });
    });
    await modal.present();
  }
}
