import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LogService } from './log.service';
import { Router } from '@angular/router';
import { retry, catchError } from 'rxjs/operators';
import { CardInformation } from '../models/card-information';

@Injectable({
  providedIn: 'root'
})
export class BinService {
  apiURL = 'https://lookup.binlist.net/';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private httpClient: HttpClient, private router: Router, private logger: LogService) {}

  getCardInformation(cardNumber: string): Observable<CardInformation> {
    return this.httpClient.get<CardInformation>(this.apiURL + cardNumber, this.httpOptions).pipe(
      retry(1),
      catchError(err => this.handleError(err))
    );
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      if (error.status && error.status.toString() === '401') {
        localStorage.removeItem('user');
        this.router.navigate(['/authentication/login']);
      }
    }
    this.logger.error('bin service errorMessage: ', errorMessage);
    return throwError(errorMessage);
  }
}
