import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RefreshCardsService {
  private cardNumber: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  getCardNumber(): Observable<number> {
    return this.cardNumber.asObservable();
  }

  setCardNumber(newValue: number): void {
    this.cardNumber.next(newValue);
  }
}
