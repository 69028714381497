import { Pipe, PipeTransform } from '@angular/core';
import { Establishments } from 'src/api/model';

@Pipe({
  name: 'searchEstablishments'
})
export class SearchEstablishmentsPipe implements PipeTransform {
  public transform(establishments: Establishments[], searchString: string) {
    if (establishments) {
      let establishmentString;

      if (searchString === '') {
        return (establishmentString = establishments);
      } else {
        establishmentString = establishments.filter(x => {
          return (
            x.establismentName
              .toString()
              .toLowerCase()
              .split(',')
              .some(item3 => {
                return item3.includes(searchString.toLowerCase());
              }) ||
            x.city
              .toString()
              .toLowerCase()
              .split(',')
              .some(item2 => {
                return item2.includes(searchString.toLowerCase());
              })
          );
        });
        return establishmentString;
      }
    }
  }
}
