import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { FirestoreService } from 'src/app/@shared/services/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class VerifyNumberGuard implements CanActivate {
  constructor(public firestoreService: FirestoreService, public router: Router) {}
  canActivate(): boolean {
    if (Capacitor.getPlatform().toLowerCase() === 'ios') {
      return true;
    } else {
      if (!this.firestoreService.isPhoneValidated) {
        this.router.navigate(['verificar-numero']);
        return false;
      }
      return true;
    }
  }
}
