import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupByDate'
})
export class GroupByDatePipe implements PipeTransform {
  public transform(ungroupedTransactions: any) {
    const groups: any[] = [];
    const groupedTransactions: any = {};

    ungroupedTransactions?.forEach((transaction: any) => {
      let dateOnly = new Date(transaction['date']).toISOString().split('T')[0];

      if (!(dateOnly in groupedTransactions)) {
        groupedTransactions[dateOnly] = [];
      }
      groupedTransactions[dateOnly].push(transaction);
    });

    for (let group in groupedTransactions) {
      if (groupedTransactions.hasOwnProperty(group)) {
        groups.push({
          date: group,
          transactions: groupedTransactions[group]
        });
      }
    }

    return groups;
  }
}
