import {HttpClient, HttpEvent, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';

import * as __model from '../model';

export interface PostLogsParams {
  body?: __model.Logs;
}

export interface PageParams {
  pageSize: number;
  page: number;
}

export interface GetLogsByIdParams {
  id: number;
}

export interface PutLogsByIdParams {
  id: number;
  body?: __model.Logs;
}

export interface DeleteLogsByIdParams {
  id: number;
}

@Injectable()
export class LogsService {
  headers = new HttpHeaders({ "Content-Type": "application/json" });

  constructor(private http: HttpClient) {}

  getLogs(): Observable<__model.Logs[]> {
    return this.http.get<__model.Logs[]>(environment.apiUrl + `/api/Logs`, {headers: this.headers});
  }

  postLogs(params: PostLogsParams): Observable<__model.Logs> {
    const bodyParams = params.body;

    return this.http.post<__model.Logs>(environment.apiUrl + `/api/Logs`, bodyParams || {}, {headers: this.headers});
  }

  page(params: PageParams): Observable<__model.Logs[]> {
    const pathParams = {
      pageSize: params.pageSize,
      page: params.page,
    };
    return this.http.get<__model.Logs[]>(environment.apiUrl + `/api/Logs/page/${pathParams.pageSize}/${pathParams.page}`, {headers: this.headers});
  }

  getLogsById(params: GetLogsByIdParams): Observable<__model.Logs> {
    const pathParams = {
      id: params.id,
    };
    return this.http.get<__model.Logs>(environment.apiUrl + `/api/Logs/${pathParams.id}`, {headers: this.headers});
  }

  putLogsById(params: PutLogsByIdParams): Observable<void> {
    const pathParams = {
      id: params.id,
    };
    const bodyParams = params.body;

    return this.http.put<void>(environment.apiUrl + `/api/Logs/${pathParams.id}`, bodyParams || {}, {headers: this.headers});
  }

  deleteLogsById(params: DeleteLogsByIdParams): Observable<__model.Logs> {
    const pathParams = {
      id: params.id,
    };
    return this.http.delete<__model.Logs>(environment.apiUrl + `/api/Logs/${pathParams.id}`, {headers: this.headers});
  }
}
