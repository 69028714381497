import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedFunctionsService {
  private loaders: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private counter: number = 0;

  constructor() {}

  convertBrasiliaDatetimeToUTC(datetime: string) {
    if (datetime && datetime !== '') {
      return new Date(datetime.replace('T', ' ').replace('Z', '') + ' GMT-3:00').toISOString();
    } else {
      return new Date().toISOString();
    }
  }

  getCounter() {
    return this.counter;
  }

  incrementCounter() {
    this.counter++;
  }

  decrementCounter() {
    this.counter--;
  }

  getCieloSchemaName(scheme: string) {
    if (scheme) {
      switch (scheme) {
        case 'visa':
          return 'Visa';
        case 'mastercard':
          return 'Master';
        case 'amex':
          return 'Amex';
        case 'elo':
          return 'Elo';
        case 'aura':
          return 'Aura';
        case 'jcb':
          return 'JCB';
        case 'diners':
          return 'Diners';
        case 'discover':
          return 'Discover';
        case 'hipercard':
          return 'Hipercard';
        case 'hiper':
          return 'Hiper';
        default:
          return 'Visa';
      }
    } else {
      return 'Visa';
    }
  }

  getCieloSchemaImageUrl(scheme: string): string {
    if (scheme) {
      switch (scheme) {
        case 'visa':
          return '../../../../assets/img/bank/visa.png';
        case 'mastercard':
          return '../../../../assets/img/bank/master.png';
        case 'amex':
          return '../../../../assets/img/bank/amex.png';
        case 'elo':
          return '../../../../assets/img/bank/elo.png';
        case 'aura':
          return '../../../../assets/img/bank/aura.png';
        case 'jcb':
          return '../../../../assets/img/bank/jcb.png';
        case 'diners':
          return '../../../../assets/img/bank/diners.png';
        case 'discover':
          return '../../../../assets/img/bank/discover.png';
        case 'hipercard':
          return '../../../../assets/img/bank/hipercard.png';
        case 'hiper':
          return '../../../../assets/img/bank/hiper.png';
        default:
          return '../../../../assets/img/bank/visa.png';
      }
    } else {
      return '../../../../assets/img/bank/visa.png';
    }
  }
}
