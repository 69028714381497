<ion-card
  *ngIf="paymentLink"
  class="ion-padding box-shadow border-radius ion-margin"
  [ngClass]="paymentLink.canceled ? 'canceled' : 'notCanceled'">
  <div class="content">
    <div class="top">
      <span>
        {{ paymentLink.establishmentName }}
      </span>
      <span>
        {{ paymentLink.canceled ? 'Canceled' : '' }}
      </span>
    </div>
    <div class="top">
      <span>{{ paymentLink.valueEUR | currency: 'EUR' }}</span>
      <span>{{ paymentLink.date | date: 'ddMMMyy,  HH:mm' }}</span>
    </div>
  </div>
</ion-card>
